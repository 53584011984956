import React, { useState, useEffect } from "react";
import {
  Card,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  MenuItem,
  IconButton,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { toastoptions } from "utils/toastoptions";
import { toast } from "react-hot-toast";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import assignmenticon from "../../../assets/images/assignmenticon.png";
import { useNavigate } from "react-router";
import {
  getUserLanguageAsync,
  updateUserLanguageAsync,
} from "redux/register/register.async";
import { getStudentByIdAsync } from "redux/async.api";
import { useDispatch, useSelector } from "react-redux";

function LanguageDialog({ open, handleClose, setOpenPopover }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectLanguage, setSelectLanguage] = useState("");
  const { userInfo } = useSelector((state) => state.userInfo);
  const { userLangLoader, userLanguages, updateLangLoader, updateLang } =
    useSelector((state) => state.register);

  useEffect(() => {
    dispatch(getUserLanguageAsync());
  }, []);

  const handleLanguageChange = (event) => {
    setSelectLanguage(event.target.value);
  };

  const submitSwitchLang = () => {
    let payload = {
      language: selectLanguage,
    };

    dispatch(updateUserLanguageAsync(payload)).then((res) => {
      if (res?.payload?.status == 200) {
        dispatch(getStudentByIdAsync(userInfo?.userId));
        toast.success("Language Switched Successfully");
        handleClose();
        setOpenPopover(null);
        navigate("/app/dashboard");
      }
    });
  };

  return (
    <Box>
      <Dialog open={open} fullWidth minHeight="100vh">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginRight={2}
        >
          <Box>
            <DialogTitle id="dialog-title">
              <Typography
                sx={{ color: "green", fontSize: "40px", fontWeight: 600 }}
              >
                Switch Language
              </Typography>
            </DialogTitle>
          </Box>
          <Box>
            <IconButton color="primary" size="large">
              <HighlightOffIcon onClick={handleClose} />
            </IconButton>
          </Box>
        </Box>
        <DialogContent sx={{ display: "flex" }}>
          <Grid container spacing={1.5}>
            <Grid item xs={3}></Grid>
          </Grid>
        </DialogContent>

        <Box
          sx={{
            background: "rgba(190, 149, 190, 0.1)",
            ml: { xs: 2, sm: 4 },
            mr: { xs: 2, sm: 4 },
            borderRadius: "20px",
            mb: 6,
          }}
        >
          <Box>
            <DialogTitle id="dialog-title">
              <Typography
                sx={{ color: "red", fontSize: { xs: "12px", sm: "15px" } }}
              >
                <Box sx={{ width: "100%", mb: 3 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Language
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectLanguage}
                      label="Language"
                      onChange={handleLanguageChange}
                    >
                      {userLanguages?.data?.map((item, ind) => {
                        return (
                          <MenuItem value={item?.language}>
                            {item?.language}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Typography>
            </DialogTitle>
          </Box>

          <DialogContent sx={{ display: "flex", pb: 3 }}>
            <Grid container spacing={1.5}>
              <Grid item xs={6} sm={5}>
                {/* <img src={assignmenticon} alt="assignment" width="90px" /> */}
              </Grid>
              <Grid
                item
                xs={6}
                sm={7}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: "100%",
                    borderRadius: "12px",
                    height: "40px",
                    width: { xs: "100%", sm: "45%" },
                    color: "white",
                    bgcolor: "primary.main",
                    pt: "20px",
                    pb: "20px",
                  }}
                  disabled={!selectLanguage}
                  onClick={submitSwitchLang}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: 600,
                    }}
                  >
                    Submit
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
}

export default LanguageDialog;
