import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const submitReFresherAssignmentAsync = createAsyncThunk(
  "web/submitReFresherAssignment",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/submitReFresherAssignment`,
      payload,
      toolkit
    );
  }
);

export const getReFresherAssignmentAsync = createAsyncThunk(
  "web/getReFresherAssignment",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getReFresherAssignment?batchId=${payload?.batchId}`,
      payload,
      toolkit
    );
  }
);

export const userAssignmentStautsAsync = createAsyncThunk(
  "web/userAssignmentStauts",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/userAssignmentStauts?batchId=${payload?.batchId}`,
      payload,
      toolkit
    );
  }
);
