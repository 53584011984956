import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  submitReFresherAssignmentAsync,
  getReFresherAssignmentAsync,
  userAssignmentStautsAsync,
} from "./refreshAssignment.async";

const initialState = {
  assignmentLoader: false,
  assignment: [],
  getAssignLoader: false,
  getAssignment: [],
  assignStatusLoader: false,
  assignStatus: [],
};

export const refreshAssignSlice = createSlice({
  name: "Chapter",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(submitReFresherAssignmentAsync.pending),
      (state) => {
        state.assignmentLoader = true;
        state.assignment = [];
      }
    );
    builder.addMatcher(
      isAnyOf(submitReFresherAssignmentAsync.fulfilled),
      (state, action) => {
        state.assignmentLoader = false;
        state.assignment = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(submitReFresherAssignmentAsync.rejected),
      (state, action) => {
        state.assignmentLoader = false;
        state.assignment = [];
      }
    );

    builder.addMatcher(
      isAnyOf(getReFresherAssignmentAsync.pending),
      (state) => {
        state.getAssignLoader = true;
        state.getAssignment = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getReFresherAssignmentAsync.fulfilled),
      (state, action) => {
        state.getAssignLoader = false;
        state.getAssignment = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getReFresherAssignmentAsync.rejected),
      (state, action) => {
        state.getAssignLoader = false;
        state.getAssignment = [];
      }
    );

    builder.addMatcher(isAnyOf(userAssignmentStautsAsync.pending), (state) => {
      state.assignStatusLoader = true;
      state.assignStatus = [];
    });
    builder.addMatcher(
      isAnyOf(userAssignmentStautsAsync.fulfilled),
      (state, action) => {
        state.assignStatusLoader = false;
        state.assignStatus = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(userAssignmentStautsAsync.rejected),
      (state, action) => {
        state.assignStatusLoader = false;
        state.assignStatus = [];
      }
    );
  },
});

export default refreshAssignSlice.reducer;
