import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  generateOtpAsync,
  loginSelectedUserAsync,
  loginWithMPinAsync,
  switchAccountAsync,
  userSignupAsync,
  verifyOtpAsync,
  staffLoginWithTIdAsync,
  staffLoginWithEmpCodeAsync,
  loginWithMpinAsync,
  userLanguageAsync,
  getUserLanguageAsync,
  loginwithloginIdAsync,
  updateUserLanguageAsync,
} from "./register.async";

const initialState = {
  registerLoader: false,
  register: [],
  otpVerifyResponce: {},
  IdWithLogin: [],
  createaccount: [],
  secondaryUser: false,
  mPinLoader: false,
  mPinResponce: {},
  mTidResponce: {},
  mEmpIdResponce: {},
  switchaccount: {},
  systemToken: null,
  loginLoader: false,
  loginUser: [],
  langLoader: false,
  language: [],
  userLangLoader: false,
  userLanguages: [],
  loginIdLoader: false,
  loginIdResponse: {},
  updateLangLoader: false,
  updateLang: [],
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        generateOtpAsync.pending,
        verifyOtpAsync.pending,
        loginSelectedUserAsync.pending,
        userSignupAsync.pending,
        loginWithMPinAsync.pending,
        switchAccountAsync.pending,
        staffLoginWithTIdAsync.pending,
        staffLoginWithEmpCodeAsync.pending,
        loginWithMpinAsync.pending,
        userLanguageAsync.pending,
        loginwithloginIdAsync.pending
      ),
      (state) => {
        state.registerLoader = true;
        state.mPinLoader = true;
        state.loginLoader = true;
        state.langLoader = true;
        state.loginIdLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(updateUserLanguageAsync.pending), (state) => {
      state.updateLangLoader = true;
    });

    builder.addMatcher(
      isAnyOf(updateUserLanguageAsync.fulfilled),
      (state, action) => {
        state.updateLangLoader = false;
        state.updateLang = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(updateUserLanguageAsync.rejected),
      (state, action) => {
        state.updateLangLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(getUserLanguageAsync.pending), (state) => {
      state.userLangLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getUserLanguageAsync.fulfilled),
      (state, action) => {
        state.userLangLoader = false;
        state.userLanguages = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getUserLanguageAsync.rejected),
      (state, action) => {
        state.userLangLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(userLanguageAsync.fulfilled),
      (state, action) => {
        state.langLoader = false;
        state.language = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(loginWithMpinAsync.fulfilled),
      (state, action) => {
        state.loginLoader = false;
        state.loginUser = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(generateOtpAsync.fulfilled), (state, action) => {
      state.registerLoader = false;
      state.register = action.payload;
    });
    builder.addMatcher(isAnyOf(verifyOtpAsync.fulfilled), (state, action) => {
      state.registerLoader = false;
      state.otpVerifyResponce = action.payload;
    });
    builder.addMatcher(
      isAnyOf(loginSelectedUserAsync.fulfilled),
      (state, action) => {
        state.registerLoader = false;
        state.IdWithLogin = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(loginWithMPinAsync.fulfilled),
      (state, action) => {
        state.mPinLoader = false;
        state.mPinResponce = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(staffLoginWithTIdAsync.fulfilled),
      (state, action) => {
        state.mPinLoader = false;
        state.mTidResponce = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(staffLoginWithEmpCodeAsync.fulfilled),
      (state, action) => {
        state.mPinLoader = false;
        state.mEmpIdResponce = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(userSignupAsync.fulfilled), (state, action) => {
      state.registerLoader = false;
      state.createaccount = action.payload;
    });
    builder.addMatcher(
      isAnyOf(switchAccountAsync.fulfilled),
      (state, action) => {
        state.registerLoader = false;
        state.switchaccount = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(loginwithloginIdAsync.fulfilled),
      (state, action) => {
        state.loginIdLoader = false;
        state.loginIdResponse = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        generateOtpAsync.rejected,
        verifyOtpAsync.rejected,
        loginSelectedUserAsync.rejected,
        userSignupAsync.rejected,
        loginWithMPinAsync.rejected,
        switchAccountAsync.rejected,
        staffLoginWithTIdAsync.rejected,
        staffLoginWithEmpCodeAsync.rejected,
        loginWithMpinAsync.rejected,
        userLanguageAsync.rejected,
        loginwithloginIdAsync.rejected
      ),
      (state, action) => {
        state.registerLoader = false;
        state.mPinLoader = false;
        state.loginLoader = false;
        state.language = false;
        state.loginIdLoader = false;
      }
    );
  },
  reducers: {
    SystemToken: (state, action) => {
      state.systemToken = action.payload;
    },
    emptyregister: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyregister, SystemToken } = registerSlice.actions;

export default registerSlice.reducer;
