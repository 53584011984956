import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const getUserLanguageAsync = createAsyncThunk(
  "web/getUserLanguage",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getUserLanguage`, payload, toolkit);
  }
);

export const generateOtpAsync = createAsyncThunk(
  "web/generateOTP",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/generateOTP`, payload, toolkit);
  }
);

export const verifyOtpAsync = createAsyncThunk(
  "web/verifyOTP",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/verifyOTP`, payload, toolkit);
  }
);

export const loginSelectedUserAsync = createAsyncThunk(
  "web/loginSelectedUser",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/loginWithUserId`, payload, toolkit);
  }
);

export const userSignupAsync = createAsyncThunk(
  "user/userSignup",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/userSignup`, payload, toolkit);
  }
);

export const loginWithMPinAsync = createAsyncThunk(
  "user/loginWithMpin",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/loginWithMpin`, payload, toolkit);
  }
);

export const updateUserLanguageAsync = createAsyncThunk(
  "user/updateUserLanguage",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateUserLanguage`, payload, toolkit);
  }
);

export const staffLoginWithTIdAsync = createAsyncThunk(
  "user/staffLoginWithTId",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/staffLoginWithTId`, payload, toolkit);
  }
);

export const staffLoginWithEmpCodeAsync = createAsyncThunk(
  "user/staffLoginWithEmpCode",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/staffLoginWithEmpCode`,
      payload,
      toolkit
    );
  }
);

export const userLanguageAsync = createAsyncThunk(
  "user/userLanguage",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/userLanguage`, payload, toolkit);
  }
);

export const loginWithMpinAsync = createAsyncThunk(
  "user/loginWithMpin",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/loginWithMpin`, payload, toolkit);
  }
);

export const loginwithloginIdAsync = createAsyncThunk(
  "user/loginwithloginId",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/loginwithloginId`, payload, toolkit);
  }
);

export const switchAccountAsync = createAsyncThunk(
  "admin/switchAccount",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/switchAccount`, payload, toolkit);
  }
);
